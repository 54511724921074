import React from 'react';
import { Image } from './FaqStyles';

const faqList = [
    {
        title: 'Jak długo trwa ładowanie?',
        content: (
            <span>
                Pełne ładowanie baterii Ozobota trwa 30-40 minut.
                W pełni naładowana bateria wystarcza na około 90 minut ciągłej pracy.
            </span>
        ),
    },
    {
        title: 'Właśnie otrzymałem robota jako prezent, ale nigdy wcześniej o nim nie słyszałem. Co mam teraz zrobić?',
        content: (
            <span>
                Odwiedź naszą podstronę
                {' '}
                <a href="/pl/akademia/scenariusze">Scenariusze</a>
                , gdzie znajdziesz mnóstwo materiałów edukacyjnych, dzięki czemu bez
                przeszkód będziesz mógł wykorzystać możliwości robotów. Możesz skorzystać również z
                {' '}
                <a href="http://www.youtube.com/watch?v=DPre2Anny2M">
                    Filmu Instruktażowego
                </a>
                . Film prezentuje jak bawić się i uczyć z robotem, od tworzenia tras za pomocą papieru i mazaków,
                poprzez tworzenie labiryntów, aż do kodowania przy użyciu komputera lub tabletu.
            </span>
        ),
    },
    {
        title: 'Gdzie mogę znaleźć dodatkowe scenariusze lekcji i inne materiały edukacyjne?',
        content: (

            <span>
                Odwiedź naszą podstronę
                {' '}
                <a href="/pl/akademia/scenariusze">Scenariusze</a>
                , gdzie znajdziesz mnóstwo materiałów edukacyjnych, dzięki czemu bez przeszkód będziesz mógł
                wykorzystać możliwości robotów. Możesz skorzystać również z
                {' '}
                <a href="http://www.youtube.com/watch?v=DPre2Anny2M">
                    Filmu instruktażowego
                </a>
                . Film prezentuje jak bawić się i uczyć z robotem, od tworzenia tras za pomocą papieru i mazaków,
                poprzez tworzenie labiryntów, aż do kodowania przy użyciu komputera lub tabletu.
            </span>
        ),
    },
    {
        title: 'Gdzie znajdę więcej informacji na temat Zestawu Szkolnego?',
        content: (
            <span>
                W celu zapoznania się z zawartością Zestawu Szkolnego wejdź
                {' '}
                <a href="/pl/szkolenia">
                    Tutaj
                </a>
                . Na tej stronie znajdziesz formularz zgłoszeniowy, dzięki któremu
                otrzymasz więcej informacji lub zamówisz swój własny Zestaw Szkolny!
            </span>
        ),
    },
    {
        title: 'Jak zaprogramować robota za pomocą linii narysowanych mazakami?',
        content: (
            <span>
                Odwiedź podstronę
                {' '}
                <a href="/pl/akademia/scenariusze">Scenariusze</a>
                , korzystając z wyszukiwarki znajdź lekcję.
            </span>
        ),
    },
    {
        title: 'Gdzie mogę znaleźć edytor języka Scretch (OzoBlockly) dla robota?',
        content: (
            <span>
                Edytor jest darmowy, dostępny poprzez przeglądarkę internetową i znajduje się
                {' '}
                <a href="http://ozoblockly.pl">
                    Tutaj
                </a>
                .
            </span>
        ),
    },
    {
        title: 'Czy trasy robota można malować dowolnymi mazakami?',
        content: (
            <span>
                Zalecamy używanie dedykowanych mazaków, dostępnych w naszym
                {' '}
                <a href="/pl/produkty">
                    Sklepie
                </a>
                , ponieważ zostały stworzone specjalnie dla naszych robotów. Oczywiście robot
                będzie działał także z innymi markami mazaków pod warunkiem, że będą to grubsze
                mazaki ze ściętą pod kątem końcówką, pozwalające narysować linie i kody o szerokości
                około 6 milimetrów. Głównie używane kolory to czerwony, niebieski, zielony i czarny.
                Robot może nie odczytywać kolorów właściwie, jeśli będą one zbyt ciemne lub zbyt jasne.
            </span>
        ),
    },
    {
        title: 'Jak skalibrować robota z wykorzystaniem kartki papieru i czarnego mazaka?',
        content: (
            <span>
                <p>
                    W celu kalibracji do pracy na papierze narysuj czarnym mazakiem koło o średnicy
                    około 4 cm i postępuj zgodnie z instrukcjami.
                </p>
                <ol>
                    <li>
                        Wciśnij i przytrzymaj przycisk zasilania przez 2 sekundy aż robot zacznie
                        błyskać białym światłem.

                    </li>
                    <li>Umieść go na czarnym polu o średnicy około 4 cm.</li>
                    {' '}
                    <li>
                        Robot zacznie błyskać światłem niebieskim, ruszy do przodu i wtedy zacznie
                        błyskać światłem zielonym.

                    </li>
                    {' '}
                    <li>
                        Kiedy błyska światłem zielonym oznacza to, że jest poprawnie skalibrowany.
                        Jeżeli mruga światłem czerwonym musisz powtórzyć kroki 1-3. Zawsze kalibruj
                        robota na takiej powierzchni, na jakiej masz zamiar z nim pracować.

                    </li>
                    {' '}
                </ol>
            </span>
        ),
    },
    {
        title: 'Jak skalibrować robota z wykorzystaniem komputera lub tabletu?',
        content: (
            <span>
                <p>
                    Przytrzymaj przycisk zasilania na robocie przez 2 sekundy - zamruga
                    on białym światłem kiedy będzie gotowy. Zwolnij przycisk i umieść
                    robota na białym obszarze twojego ekranu. Ozobot zacznie mrugać białym
                    światłem. Jeśli zamruga zielonym światłem oznacza to, że został poprawnie
                    skalibrowany. Jeśli zaś zamruga czerwonym światłem, zacznij procedurę jeszcze raz.
                </p>
                <p>
                    <strong>Wskazówka:</strong>
                    {' '}
                    kalibracja cyfrowa i kalibracja na papierze to dwie odmienne i niezależne procedury,
                    dlatego nie mogą być używane zamiennie. Zawsze kalibruj robota na takiej powierzchni,
                    na jakiej masz zamiar z nim pracowąć. Możesz także
                    {' '}
                    <a
                        href="https://www.youtube.com/watch?v=DPre2Anny2M"
                    >
                        Obejrzeć film
                    </a>
                    {' '}
                    instruktażowy dotyczący kalibracji i pracy z urządzeniem przy użyciu
                    {' '}
                    <a
                        href="http://ozoblockly.pl"
                    >
                        Edytora
                    </a>
                    .
                </p>
            </span>
        ),
    },
    {
        title: 'Robot ma problemy z podążaniem za liniami na papierze.',
        content: (
            <span>
                <p>
                    Robot używaja czujników optycznych, aby podążać za linią. Aby czujnik działały
                    prawidłowo muszą być skalibrowane przed każdym użyciem urządzenia i za każdym razem,
                    gdy przestawiasz się z pracy na papierze - rysowane trasy na pracę z użyciem tabletu,
                    lub odwrotnie. W celu kalibracji do pracy na papierze narysuj czarnym mazakiem koło
                    o średnicy około 4 cm i postępuj zgodnie z instrukcjami.
                </p>
                <ol>
                    <li>
                        Wciśnij i przytrzymaj przycisk zasilania przez 2 sekundy aż robot zacznie błyskać
                        białym światłem.
                    </li>
                    <li>Umieść go na czarnym polu o średnicy około 4 cm.</li>
                    <li>
                        Kiedy błyska światłem zielonym oznacza to, że jest poprawnie skalibrowany. Jeżeli
                        mruga światłem czerwonym musisz powtórzyć kroki 1-3. Zawsze kalibruj robota na takiej
                        powierzchni, na jakiej masz zamiar z nim pracowąć.
                    </li>
                    <li>
                        Robot zacznie błyskać światłem niebieskim, ruszy do przodu i wtedy zacznie błyskać
                        światłem zielonym.
                    </li>
                </ol>
            </span>
        ),
    },
    {
        title: 'Robot nie odczytuje kodów narysowanych na papierze.',
        content: (
            <span>
                Odwiedź podstronę
                {' '}
                <a href="/pl/akademia/scenariusze">Scenariusze</a>
                , korzystając z wyszukiwarki znajdź lekcję
                {' '}
                „Zasady rysowania linii.”
            </span>
        ),
    },
    {
        title: 'Robot nie odczytuje linii oraz/lub kodów na tablecie.',
        content: (
            <span>
                Upewnij się, że jasność ekranu twojego urządzenia jest ustawiona na 100%. Jeśli wciąż masz
                problemy, zajrzyj do panelu „Regulacja Ozobota” w jednej z naszych aplikacji i wybierz opcję
                „kalibracja czujników” (calibrate sensors). Postępuj zgodnie z instrukcjami na ekranie, w przypadku
                problemów np. na niektórych urządzeniach z systemem Android (starsze urządzenia korzystają
                z technologii PWM do kontroli jasności ekranu - ekran okresowo zmienia się na czarny
                z częstotliwością niezauważalną dla ludzkiego oka). W związku z tym, że czujniki robota próbkują
                ekran 150 razy na sekundę, takie czarne przebłyski mogą mylić robota i powodować problemy zarówno
                z podążaniem po linii, jak i rozpoznawaniem kolorowego kodu. Na niektórych urządzeniach problem
                rozwiązuje ustawienie jasności ekranu na 100%, jednak nie na wszystkich, co czyni je
                niekompatybilnymi.
            </span>
        ),
    },
    {
        title: 'Jak mogę wyczyścić koła swojego robota?',
        content: (
            <span>
                Regularne czyszczenie kół jest niezbędne dla utrzymania prawidłowego funkcjonowania
                i przedłużania żywotności urządzenia. Postaw wyłączonego robota na czystym białym papierze
                i przesuń go kilka razy, delikatnie w tył i w przód, pomoże to pozbyciu się nagromadzonych
                zabrudzeń. Można też użyć sprężonego powietrza, albo poprostu dmuchnąc w przerwy pomiędzy
                obudową a kołami. Dzięki odpowiedniemu dbaniu o koła zwiększy się prezycja robota, będzie
                on jeździł z mniejszymi odchyleniami od prostej linii.
            </span>
        ),
    },
    {
        title: 'Jedno z kół wydaje się obracać w innym tempie, jak mogę to naprawić?',
        content: (
            <span>
                Jeśli posiadasz tablet o dużym ekranie (np. 9 cali lub więszky), otwórz panel Ozobot TuneUp
                w jednej z naszych aplikacji (np. OzoGroove) i wybierz opcję regulacji silnika (tune motors).
                Postępuj zgodnie z instrukcjami na ekranie.
            </span>
        ),
    },
    {
        title: 'Zdaje się, że mojemu robotowi pogorszyła się przyczepność, zdarza mu się utknąć na torze.',
        content: (
            <span>
                Układ napędowy robota oparty jest na tarciu i z czasem mogą pojawić się problemy mechaniczne
                w związku z nagromadzonym w podwoziu zanieczyszczeniem takim jak kurze, tłuszcz itp. Postaw
                wyłączonego robota na czystym białym papierze i przesuń go kilka razy, delikatnie w tył i
                w przód, pomoże to pozbyciu się nagromadzonych zabrudzeń. Można też użyć sprężonego powietrza,
                albo poprostu dmuchnąc w przerwy pomiędzy obudową a kołami. Dzięki odpowiedniemu dbaniu o koła
                zwiększy się prezycja robota, będzie on jeździł z mniejszymi odchyleniami od prostej linii.
            </span>
        ),
    },
    {
        title: 'Czy robot jest wodoodporny?',
        content: (
            <span>
                Zdecydowanie NIE, absolutnie nie należy zanużać robota w wodzie, a przy pracy w pobliżu wody
                lub innych płynów zaleca się zachowanie szczególnej ostrożności.
            </span>
        ),
    },
    {
        title: 'Robot nie funkcjonuje poprawnie, gdy używam go na zewnątrz lub w mocno oświetlonym pomieszczeniu.',
        content: (
            <span>
                Czujniki urządzenia wrażliwe są na światło. Jeśli Twój robot sprawia problemy w czasie pracy
                w świetle dziennym lub w mocno oświetlonym pomieszczeniu, spróbuj osłonić go przed bezpośrednim
                jego działaniem np. odsuń go od okna lub od jasno świecącej lampy.
            </span>
        ),
    },
    {
        title: 'Nie mogę pobrać aplikacji na swoje urządzenie.',
        content: (
            <span>
                Aplikacje przygotowane dla robota działają na wybranych urządzeniach z systemem iOS lub Android.
                Aplikacja „Ozobot” działa na wszystkich urządeniach iPad z wyjątkiem urządzeń I generacji - systemy
                iOS 6.0 lub nowszy. W przypadku urządzeń z systemem Android, główne wymagania jakie musi spełniać
                urządzenie to: ekran 9” lub większy, dostępność kamery, system Android 3.2 lub nowszy. Jeśli Twóje
                urządzenie nie spełnia któregoś z powyższych wymagań, możesz nie być w stanie pobrać i zainstalować
                aplikacji. „OzoGroove” jest uniwersalną aplikacją (dla systemów iOS oraz Android) i działa zarówno
                na smartfonach jak i tabletach. Wymagania dotyczące kamery i wersji systemu są takie same jak w
                przypadku aplikacji „Ozobot.
            </span>
        ),
    },
    {
        title: 'Jaka jest różnica między robotem Ozobot 2.0 Bit a robotem Ozobot Evo?',
        content: (
            <span>
                <p>Porównanie naszych różnych produktów znajduje się w tabeli poniżej.</p>
                <a
                    href={`${process.env.REACT_APP_CDN_URL}faq-tabela-porownawcza.png`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Image
                        src={`${process.env.REACT_APP_CDN_URL}faq-tabela-porownawcza.png`}
                        alt=""
                    />
                </a>
            </span>
        ),
    },
    {
        title: 'Jakie są warunki zwrotu zakupionego towaru?',
        content: (
            <span>
                <a href="/pl/gwarancja">Tutaj</a>
                {' '}
                znajdziesz informacje dotyczące gwarancji oraz zwrotu zakupionego towaru.
            </span>
        ),
    },
];

export default faqList;
