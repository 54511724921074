import React, { useState, useEffect, useContext } from 'react';
import queryString from 'querystring';
import Currency from 'react-currency-formatter';
import {
    PaymentInfo,
    RowHeader,
    SuccessPaidParagraph,
    StyledContainer,
    TotalRow,
    ProductsCol,
    List,
    DeliveryColumn,
    PriceColumn,
    ProductsRow,
    InfoContainer,
} from './OrderPreviewStyles';
import CartItem from '../../Cart/CartItem/CartItem';
import { get, getNoAuth } from '../../../api/Api';
import { Empty, Label, Labels } from '../../Cart/CartStyles';
import AppContext from '../../../context/app-context';
import EmptyOrderPreview from './EmptyOrderPreview';
import escapeDiacritics from '../../../tools/escapeDiacritics';
import {
    DescriptionDetails, DescriptionList, DescriptionTerm, StyledPriceMain,
} from '../../CartFooterBar/CartFooterBarStyles';
import {
    Addresses, AddressHeader, AddressSection, Detail,
} from '../../Summary/SummaryStyles';
import { concatPostCodeWithCity } from '../../Summary/Summary';
import { ONLINE_TRANSFER, STANDARD_TRANSFER } from '../../Payments/Payments';

export const DEFAULT_ACCOUNT_NUMBER = '10 2490 0005 0000 4600 1211 9814';
export const PAYMENT_TYPE_TRANSFER = 1;

const PAYMENT_WAITING = 0;
const PAYMENT_PAID = 1;
const PAYMENT_INCORRECT = 2;

const SHIPMENT_SENT = 2;
const SHIPMENT_SENT_COD = 3;
const SHIPMENT_PERSONAL = 5;
const SHIPMENT_ELECTRONICALLY = 6;
const OLD_EDUSENSE_ORDERS_PREVIEW_URL = 'https://old.edu-sense.com/pl/zamowienie/?h=';

export default function OrderPreview({ location }) {
    const [order, setOrder] = useState(null);
    const [error, setError] = useState(null);
    const [paymentUrl, setPaymentUrl] = useState(null);
    const query = queryString.parse(location.search);
    const hash = query['?h'];
    const isSuccessPaid = query.s !== undefined && query.s === 'c';

    const appContext = useContext(AppContext);
    const coursesUrl = appContext.routeMap.courses.path[appContext.lang];
    const productsUrl = appContext.routeMap.products.path[appContext.lang];

    const [deliveries, setDeliveries] = useState([]);

    useEffect(() => {
        getNoAuth('deliveries').then((deliveries) => setDeliveries(deliveries));
    }, []);

    useEffect(() => {
        get(`orders/hash/${hash}`).then((result) => {
            setPaymentUrl(result.payment_url);
            result = result.order;
            result.items = result.items.map((item) => {
                const itemSum = item.price * item.quantity;
                const areCourses = item.product.categories.filter(
                    (category) => category.for_courses === true,
                ).length > 0;

                return {
                    id: item.id,
                    sku: item.product.sku,
                    price: item.price,
                    quantity: item.quantity,
                    image: item.product.main_image && item.product.main_image.path,
                    name: item.name,
                    priceBefore: item.price_before,
                    priceAfterDiscount: item.price_after_discount || item.price,
                    sum: itemSum,
                    url: `${areCourses ? coursesUrl : productsUrl}/${item.product.slug}`,
                    contentType: item.product.marketing[0] && item.product.marketing[0].fb_content_type,
                };
            });
            setOrder(result);
        }).catch((error) => {
            if (error.status === 404) {
                window.location = OLD_EDUSENSE_ORDERS_PREVIEW_URL + hash;
            } else {
                setError(error);
            }
        });
    }, [hash, productsUrl, coursesUrl]);

    const renderPaymentInfo = () => {
        const transactionAccNo = order.service_data.transaction_account_number;
        if (order?.payment.invoice_name) {
            return (
                <PaymentInfo>
                    <span className="header">Faktura VAT zostanie wysłana na Twój adres e-mail w terminie 2 dni.</span>
                    <br />
                    Jeśli chcesz przyspieszyć realizację zamówienia wykonaj przelew już teraz na poniższe konto bankowe:
                    <br />
                    <br />
                    Zamówienie nr
                    {' '}
                    {order.id}
                    <br />
                    <small>NUMER KONTA:</small>
                    {' '}
                    {transactionAccNo === undefined
                        ? DEFAULT_ACCOUNT_NUMBER
                        : transactionAccNo.match(/[A-Z]{2}|(?:(?:\d{2}|\d{4})(?=(\d{4})*$))/g).join(' ')}
                    <br />
                    <small>KWOTA:</small>
                    {' '}
                    <Currency
                        quantity={+order?.payment.payment_price}
                        currency="PLN"
                        locale="pl_PL"
                        decimal=","
                    />
                    <br />
                    <small>TYTUŁ PRZELEWU:</small>
                    {' '}
                    {order.service_data.transaction_id}
                    <br />
                    <small>NAZWA ODBIORCY:</small>
                    {' '}
                    EduSense SA
                    <br />
                    <small>ADRES ODBIORCY:</small>
                    {' '}
                    ul. Dobrzańskiego 3, 20-262 Lublin
                </PaymentInfo>
            );
        }

        return (
            <PaymentInfo>
                Wykonaj przelew na poniższe konto bankowe:
                <br />
                <br />
                Zamówienie nr
                {' '}
                {order.id}
                <br />
                <br />
                <small>NUMER KONTA:</small>
                {' '}
                {order.service_data.transaction_account_number === undefined
                    ? DEFAULT_ACCOUNT_NUMBER
                    : order.service_data.transaction_account_number
                        .match(/[A-Z]{2}|(?:(?:\d{2}|\d{4})(?=(\d{4})*$))/g).join(' ')}
                <br />
                <small>KWOTA:</small>
                {' '}
                <Currency
                    quantity={+order?.payment.payment_price}
                    currency="PLN"
                    locale="pl_PL"
                    decimal=","
                />
                <br />
                <small>TYTUŁ PRZELEWU:</small>
                {' '}
                {order.service_data.transaction_id}
                <br />
                <small>NAZWA ODBIORCY:</small>
                {' '}
                EduSense SA
                <br />
                <small>ADRES ODBIORCY:</small>
                {' '}
                ul. Dobrzańskiego 3, 20-262 Lublin
            </PaymentInfo>
        );
    };

    const renderShipmentFollowLink = (deliveryType, packageNumber) => {
        switch (deliveryType) {
        case 'inpost':
            return (
                <a
                    href={`https://inpost.pl/sledzenie-przesylek?number=${packageNumber}`}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    LOKALIZUJ PRZESYŁKĘ
                </a>
            );
        case 'gls':
            return (
                <a
                    href={`https://gls-group.eu/PL/pl/sledzenie-paczek?match=${packageNumber}`}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    LOKALIZUJ PRZESYŁKĘ
                </a>
            );
        case 'pocztapolska':
            return (
                <a
                    href={`http://emonitoring.poczta-polska.pl/?numer=${packageNumber}`}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    LOKALIZUJ PRZESYŁKĘ
                </a>
            );
        default:
            return null;
        }
    };

    const ContactDetails = {
        header: 'Dane kontaktowe',
        details: [
            `${order?.client.first_name || ''} ${order?.client.last_name || ''}`,
            order?.client.email,
            order?.client.phone,
        ],
    };

    const ShippmentAddress = {
        header: 'Dane do wysyłki',
        details: [
            order?.client.recipient_name,
            order?.client.recipient_address,
            concatPostCodeWithCity(order?.client.recipient_postcode, order?.client.recipient_city),
            order?.client.recipient_comment,
            order?.delivery_extra ? `Wybrany paczkomat: ${order.delivery_extra}` : undefined,
            order?.delivery_inpost_address,
        ],
    };

    const InvoiceDetails = {
        header: 'Dane nabywcy na fakturze',
        details: [
            order?.payment.invoice_name,
            order?.payment.invoice_address,
            concatPostCodeWithCity(
                order?.payment.invoice_postcode ? order?.payment.invoice_postcode : order?.client.recipient_postcode,
                order?.payment.invoice_city,
            ),
            order?.payment.invoice_tax_id,
        ],
    };

    const InvoiceRecipientDetails = {
        header: 'Dane odbiorcy na fakturze',
        details: [
            order?.payment.recipient_name,
            order?.payment.recipient_address,
            concatPostCodeWithCity(
                order?.payment.recipient_postcode
                    ? order?.payment.recipient_postcode : order?.client.recipient_postcode,
                order?.payment.recipient_city,
            ),
            order?.payment.recipient_tax_id,
        ],
    };

    const currentTime = new Date();
    const createdAt = new Date(order?.payment?.created_at);
    const timeFromPaymentInMinutes = (currentTime.getTime() - createdAt.getTime()) / (60 * 1000);
    const renderOrder = () => (order
        ? (
            <>
                <InfoContainer>
                    {isSuccessPaid
                    && (
                        <SuccessPaidParagraph
                            className={order.service_data.payment_type === PAYMENT_TYPE_TRANSFER && 'two-columns'}
                        >
                            Dziękujemy za złożenie zamówienia. Zamówienie zostanie wysłane po zaksięgowaniu wpłaty.
                            Wkrótce otrzymasz potwierdzenie płatności na podany adres e-mail.
                        </SuccessPaidParagraph>
                    )}
                    {order.service_data.payment_type === PAYMENT_TYPE_TRANSFER && renderPaymentInfo()}
                </InfoContainer>
                <RowHeader>
                    Zamówienie nr
                    {' '}
                    {order.id}
                    {(order.status.shipment_status !== SHIPMENT_SENT
                        && order.status.shipment_status !== SHIPMENT_ELECTRONICALLY
                        && order.status.shipment_status !== SHIPMENT_SENT_COD)
                && (
                    <>
                        {order.status.payment_status === PAYMENT_WAITING
                    && (
                        <>
                        &nbsp;- OCZEKUJE NA PŁATNOŚĆ &nbsp;
                            {order.service_data.payment_type !== PAYMENT_TYPE_TRANSFER
                            && timeFromPaymentInMinutes > 5
                            && <a href={paymentUrl}>WYKONAJ PŁATNOŚĆ</a>}
                        </>
                    )}
                        {order.status.payment_status === PAYMENT_PAID
                    && (
                        <>
                        &nbsp;- OPŁACONE
                        </>
                    )}
                        {order.status.payment_status === PAYMENT_INCORRECT
                    && (
                        <>
                        &nbsp;- BŁĄD PŁATNOŚCI &nbsp;
                            <a href={paymentUrl}>WYKONAJ PŁATNOŚĆ</a>
                        </>
                    )}
                    </>
                )}
                    {order.status.shipment_status === SHIPMENT_SENT
                && (
                    <>
                    &nbsp;- PACZKA WYSŁANA&nbsp;
                        {order.service_data.package_number !== undefined
                    && renderShipmentFollowLink(order.delivery_type, order.service_data.package_number)}
                    </>
                )}
                    {order.status.shipment_status === SHIPMENT_ELECTRONICALLY
                && (
                    <>
                    &nbsp;- WYSŁANE ELEKTRONICZNIE
                    </>
                )}
                    {order.status.shipment_status === SHIPMENT_SENT_COD
                && (
                    <>
                    &nbsp;- WYSŁANO ZA POBRANIEM&nbsp;
                        {order.service_data.package_number !== undefined
                    && renderShipmentFollowLink(order.delivery_type, order.service_data.package_number)}
                    </>
                )}
                    {order.status.shipment_status === SHIPMENT_PERSONAL
                && (
                    <>
                    &nbsp;- ODEBRANO
                    </>
                )}
                </RowHeader>
                <ProductsRow>
                    <ProductsCol>
                        {(order.items && order.items.length > 0)
                            ? (
                                <List>
                                    <Labels>
                                        <Label>Cena za sztukę</Label>
                                        <Label className="quantity" readOnly>Ilość</Label>
                                        {order.discount_code && !!order.discount_value
                                        && <Label className="discount">Kod rabatowy</Label>}
                                        <Label className="totalValue">Wartość</Label>
                                    </Labels>
                                    {order.items.map((item, key) => (
                                        <CartItem
                                            key={key}
                                            item={item}
                                            productDiscount={order.discount_code && order.discount_value
                                                ? item.priceAfterDiscount
                                                : null}
                                            readOnly
                                            displayDiscount
                                        />
                                    ))}
                                </List>
                            )
                            : <Empty>Brak produktów</Empty>}
                    </ProductsCol>
                </ProductsRow>
                <TotalRow>
                    <DeliveryColumn>
                        <DescriptionList>

                            <DescriptionTerm className="summary">
                                Sposób wysyłki:
                            </DescriptionTerm>
                            <DescriptionDetails className="align-left">
                                {order.delivery_type === undefined
                                    ? 'elektronicznie'
                                    : `${deliveries.find(
                                        (delivery) => delivery.type === order.delivery_type,
                                    ).name}  |  ${order.delivery_price}zł`}
                            </DescriptionDetails>

                            <DescriptionTerm className="summary">
                                Forma Płatności:
                            </DescriptionTerm>
                            <DescriptionDetails className="align-left">
                                {order?.payment.payment_type === ONLINE_TRANSFER && 'przelew elektroniczny'}
                                {order?.payment.payment_type === STANDARD_TRANSFER && 'przelew tradycyjny'}
                            </DescriptionDetails>
                        </DescriptionList>
                    </DeliveryColumn>
                    <PriceColumn>
                        <DescriptionList className="start-align">
                            <DescriptionTerm>Do&nbsp;zapłaty:</DescriptionTerm>
                            <DescriptionDetails>
                                <StyledPriceMain price={order?.payment.payment_price} />
                            </DescriptionDetails>
                        </DescriptionList>
                    </PriceColumn>
                </TotalRow>
                <Addresses>
                    {order?.client
                    && [ContactDetails, ShippmentAddress, InvoiceDetails, InvoiceRecipientDetails].map((address) => {
                        if (address.details.join('').trim().length) {
                            return (
                                <AddressSection key={address.header}>
                                    <AddressHeader>{address.header}</AddressHeader>
                                    {address.details.map((field, index) => <Detail key={index}>{field}</Detail>)}
                                </AddressSection>
                            );
                        }

                        return undefined;
                    })}
                </Addresses>
            </>
        )
        : <EmptyOrderPreview />);

    useEffect(() => window.fbq('track', 'PageView'), []);

    const { setGoogleData } = appContext;
    useEffect(() => {
        if (!isSuccessPaid) return;
        if (order === null || order.items === undefined || order.items.length === 0) return;

        const sendAnalytics = () => {
            window.fbq('track', 'Purchase', {
                content_type: 'product',
                contents: order.items.map((item) => {
                    return {
                        id: item.sku ?? item.id,
                        quantity: item.quantity
                    };
                }),
                value: order.payment.total_price_before,
                currency: 'PLN',
            }, {
                eventId: order.id
            });

            window.gtag('event', 'purchase', {
                transaction_id: order.id,
                value: order.payment.total_price_before,
                currency: 'PLN',
                shipping: order.delivery_price,
                checkout_option: order.delivery_type,
                coupon: order.discount_code,
                items: order.items.map((item) => ({
                    id: item.sku,
                    name: escapeDiacritics(item.name),
                    quantity: item.quantity,
                    price: item.price,
                })),
            });

            setGoogleData({
                ecomm_pagetype: 'purchase',
                ecomm_prodid: order.items.map((item) => item.sku),
                ecomm_totalvalue: order.payment.total_price_before,
            });
        };

        if (order.is_send_stats_to_analytics) {
            sendAnalytics();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, isSuccessPaid]);

    return (
        <StyledContainer>
            {error
                ? (
                    <RowHeader>
                        <div>Nie ma takiego zamównienia</div>
                        <small>Sprawdź link lub skontaktuj się z biurem obsługi klienta</small>
                    </RowHeader>
                )
                : renderOrder()}
        </StyledContainer>
    );
}
